import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionTeam() {
  return (
    <>
      <div className="section section-team cd-section" id="team">
        {/* ********* TEAM 1 ********* */}
        <div className="team-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">System Team</h2>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <img
                        alt="..."
                        src={require("assets/img/sections/ebm/system-team/bw1.jpg")}
                      />
                  </div>
                  <CardBody>
                    <div className="author">
                        <CardTitle tag="h4">Giorgia Verri</CardTitle>
                        <h6 className="card-category text-muted">
                          CMCC
                        </h6>
                      </div>
                    <p className="card-description text-center">
                      giorgia.verri@cmcc.it
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                     <img
                        alt="..."
                        src={require("assets/img/sections/ebm/system-team/bw.jpg")}
                      />
                    </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Nadia Pinardi</CardTitle>
                        <h6 className="card-category text-muted">University of Bologna</h6>
                      </div>
                    <p className="card-description text-center">
                      nadia.pinardi@unibo.it
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="..."
                        src={require("assets/img/sections/ebm/system-team/mw1.jpg")}
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Sahameddin Kurdistani</CardTitle>
                        <h6 className="card-category text-muted">CMCC</h6>
                      </div>
                    <p className="card-description text-center">
                    sahameddin.kurdistani@cmcc.it
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <img
                        alt="..."
                        src={require("assets/img/sections/ebm/system-team/mw.jpg")}
                      />
                  </div>
                  <CardBody>
                    <div className="author">
                        <CardTitle tag="h4">Giovanni Coppini</CardTitle>
                        <h6 className="card-category text-muted">
                         CMCC
                        </h6>
                      </div>
                    <p className="card-description text-center">
                      giovanni.coppini@cmcc.it
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="..."
                        src={require("assets/img/sections/ebm/system-team/mw2.jpg")}
                      />
                  </div>
                  <CardBody>
                     <div className="author">
                        <CardTitle tag="h4">Matteo Scuro</CardTitle>
                        <h6 className="card-category text-muted">
                          CMCC
                        </h6>
                      </div>
                    <p className="card-description text-center">
                      matteo.scuro@cmcc.it
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionTeam;

import React from "react";

// reactstrap components
import { Container, Row, Col, Card } from "reactstrap";
import { Player } from 'video-react';
// core components

function SectionSummary() {
  return (
    <>
    <div className="section text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">What is the CMCC EBM</h2>
                <h5>
                What inspired us to devise a conceptual framework to simulate the estuarine water exchange:
                </h5>
                <br />
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto" md="12">
                <ul>
                  <li>
                    <h5><b>River release </b> strongly affects the ocean circulation and dynamics from coastal scale (with the so called ‘buoyancy river plume”) to basin scale (by affecting buoyancy budget and overturning cell mechanism) </h5>
                  </li>
                  <li>
                    <h5><b>Mesoscale-resolving ocean models</b> cannot represent the estuary geometry due to their low resolution. They need to be forced at river mouths in terms of runoff, salinity and temperature</h5>
                  </li>
                  <li>
                    <h5> Most <b>estuaries</b> are<b> poorly monitored.</b> Moreover, river observations are taken far from river outlets, thus they are not representative of the estuary areas</h5>
                  </li>
                  <li>
                    <h5> <b>Freshwater input</b> into ocean models is traditionally very idealized: e.g. "<i>climatological approach</i>" to introduce the river release and at most had-hoc salt and temperature values</h5>
                  </li>
                  <li>
                    <h5> Existing estuary box models include empirical and highly parameterized formula. A <b>physically-based EBM</b> with minimal calibration and short CPU time is desirable to force regional to global ocean models and produce operational forecasts and climate scenarios</h5>
                  </li>
                </ul>
              </Col>
            </Row>
            <br />
            <br />
        </Container>
          <Container>
          <Row>
          <div className="container-fluid">
          <div className="card-image">
            <img alt="..." className="img img-raised gif-image" src={require("assets/img/sections/ebm/estuar05a_wedge.gif")} />
            <p>&copy; Image from <a href="https://oceanservice.noaa.gov/education/tutorial_estuaries/est05_circulation.html" target="_blank">NOAA</a></p>
            </div>   
          </div>
          </Row>
          <Row>
          <div className="description centered-text summaryDescription">
            <p>
          The Estuary Box Model developed by OPA Division of CMCC Foundation, CMCC EBM (Verri et al., 2020; Verri et al 2021), is a 1D numerical model based on a two-layer rectangular box linking the riverine freshwater and the salt sea waters with a resulting non zero salinity estuarine outflow and a time variable estuarine length. 
          The model has been conceived in order to represent the estuarine overturning circulation and mixing processes and to provide reliable estimates of runoff and salinity at river mouths, which in turn may be used to properly force mesoscale ocean models.
          Its development started from scratch at CMCC in 2015 on the basis of the knowledge of another EBM (Sun et al., 2017) jointly developed by the University of Connecticut (UCONN) and the National Center for Atmospheric Research (NCAR)
            </p>
            </div>
          </Row>
          </Container>
        </div>

      <div className="section section-cadet">
      <Container>
          <Col className="ml-auto mr-auto" md="8">
            <div className="section-description text-center">
              <h2 className="title-white">Highlights</h2>
              <br />
              
            </div>
          </Col>
        </Container>
        <Container>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-paper" />
                </div>
                <div className="description-white">
                  <h4 className="info-title-white">1</h4>
                  <p>
                  Regional and global <b>mesoscale ocean models</b> cannot resolve the estuarine dynamics but they need a <b>realistic</b> representation of the riverine release
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-tile-56" />
                </div>
                <div className="description-white">
                  <h4 className="info-title-white">2 </h4>
                  <p>
                  The CMCC EBM gives <b>reliable</b> estimates of the <b>net release at river mouths </b> and the coupling with ocean models admiliorate the representation of the river plume
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-layout-11" />
                </div>
                <div className="description-white">
                  <h4 className="info-title-white"> 3 </h4>
                  <p>
                  The CMCC EBM requires <b>minimal calibration</b> and <b>short CPU time</b>. These strenghts makes the model suitable for coupling with mesoscale ocean and hydrology models to produce both operational forecasts and climate scenarios 
                  </p>
                </div>
              </div>
              
            </Col>
          </Row>
          <Row>
          <div className="description-white centered-text">
          <h5>
          If you want to handle the code findings, go to the Section named “<b>Handle the CMCC EBM</b>”.
If you want to get more details on the numerical code and its governing equations, go to the Section named “Model Description”
                </h5>
                </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;

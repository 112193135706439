/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterGray() {
  return (
    <>
      <footer className="footer footer-cadet footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href=""
                  >
                   CMCC EBM
                  </a>
                </li>
                
                <li>
                  <a
                    href="/contact-us"
                    target="_blank"
                    className="mr-1"
                  >
                    Contact Us
                  </a>
                </li>

                <li>
                  <a
                    href=""
                    target="_blank"
                  >
                    License / Cookie Policy
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()} {" "}
                Developed by CMCC
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterGray;

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, ReferenceLine, Area
  } from 'recharts';
  import MathJax from "react-mathjax2";
  import React, { Component } from 'react';
  import Select from "react-select";
  import {
    Row,
    Col,
    Container,
    Navbar
  } from "reactstrap";

  import {year2003, year2004, year2005, year2006, year2007, year2008, year2009, year2010, year2011, year2012, year2013, year2014, year2015, year2016, year2017, year2018} from "./CustomDataset";

  const selectDateOptions = [
    { value: "18", label: "2018" },
    { value: "17", label: "2017" },
    { value: "16", label: "2016" },
    { value: "15", label: "2015" },
    { value: "14", label: "2014" },
    { value: "13", label: "2013" },
    { value: "12", label: "2012" },
    { value: "11", label: "2011" },
    { value: "10", label: "2010" },
    { value: "9", label: "2009" },
    { value: "8", label: "2008" },
    { value: "7", label: "2007" },
    { value: "6", label: "2006" },
    { value: "5", label: "2005" },
    { value: "4", label: "2004" },
    { value: "3", label: "2003" }
  
  ];
  
  const selectVariableOptions = [
    { value: "s", label: "Outflowing salinity" },
    { value: "v", label: "Outflowing volume flux" },
    { value: "salt", label: "Length of salt wedge intrusion" }
  ];
  
  const selectStyles = {
    container: (base, state) => ({
      ...base,
      opacity: state.isDisabled ? ".5" : "1",
      backgroundColor: "transparent",
      zIndex: "9999"
    })
  };
  
const initialState = {
  data: year2018[0],
  barIndex : 'uv',
  left : 0,
  right : 0,
  selDate: 18,
  selVar: "s"
};

class StreamingChart extends React.Component {

	constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleChangeData(dataset){
    
    if (dataset.value == "s" || dataset.value == "v" || dataset.value == "salt") 
    this.selVar = dataset.value;
    else 
    this.selDate = dataset.value;
    
    if (this.selVar == null) this.selVar = "s";
    if (this.selDate == null) this.selDate = 18;
 
    let newData;
    var index = 0;
    if (this.selVar == "s") {
      index = 0; 
  }
    else if (this.selVar == "v") index = 1;
    else if (this.selVar == "salt") index = 2;

    if (this.selDate == 18) newData = year2018[index];
    else if (this.selDate == 17) newData = year2017[index];
    else if (this.selDate == 16) newData = year2016[index];
    else if (this.selDate == 15) newData = year2015[index];
    else if (this.selDate == 14) newData = year2014[index];
    else if (this.selDate == 13) newData = year2013[index];
    else if (this.selDate == 12) newData = year2012[index];
    else if (this.selDate == 11) newData = year2011[index];
    else if (this.selDate == 10) newData = year2010[index]; 
    else if (this.selDate == 9) newData = year2009[index];
    else if (this.selDate == 8) newData = year2008[index];
    else if (this.selDate == 7) newData = year2007[index];
    else if (this.selDate == 6) newData = year2006[index];
    else if (this.selDate == 5) newData = year2005[index];
    else if (this.selDate == 4) newData = year2004[index];
    else if (this.selDate == 3) newData = year2003[index];
        
    // shift
      this.setState( ( { data : cdata, left = 0 } ) => {
      return { 
        data:newData,
        currentVariable: this.selVar
        };
    } );  

		// insert
    setTimeout( () => {
    	this.setState( ( { data : cdata } ) => {
    	 // cdata[ cdata.length - 1 ].uv = parseInt(Math.random()*10 - 5, 10);
      	return { data : newData, animation : true }
      } ); 
    }, 1200 );
  };

  setVariable(){
    return this.selDate;
  }

   renderLines (data) {
  console.log("testing->", data) 
  }
  render() {
    // const [selectedVariable, setVariable] = React.useState();
    // const [selectedDate, setDate] = React.useState();
    const dynamicLegend = ['1']

    const { data, barIndex,animation, left, right, selectedDate, selectedVariable, currentVariable } = this.state;
    return (
      <>
      <div className="section section-navigation section-navigation-custom">
            <div id="navbar-full">
              <div className="navigation-example"></div>
                <Navbar className="bg-info" expand="lg">
                  <Container>
                    <Col md="4">
                      <p className="navbar-brand">Select year and variable</p>
                    </Col>
                    <Col md="4">
                      {/* <p className="title-uppercase" >Year</p> */}
                      <Select
                        styles={selectStyles}
                        className="react-select react-select-default"
                        classNamePrefix="react-select"
                        name="dateSelect"
                        value={selectedDate}
                        defaultValue={{ value: "18", label: "2018" }}
                        onChange={this.handleChangeData.bind( this )}
                        options={selectDateOptions}
                        placeholder="CHOOSE DATE"
                      />
                    </Col>
                    <Col md="4">
                    {/* <p className="title-uppercase">CMCC EBM Variable</p> */}
                      <Select
                        styles={selectStyles}
                        className="react-select react-select-default"
                        classNamePrefix="react-select"
                        name="defaultSelect"
                        value={selectedVariable}
                        defaultValue={  { value: "s", label: "Outflowing salinity" }}
                        onChange={this.handleChangeData.bind( this )}
                        // onChange={(value) => setVariable(value)}
                        options={selectVariableOptions}
                        placeholder="CHOOSE VARIABLE"
                      />
                    </Col>
                  </Container>
                </Navbar>
                </div>
                </div>
                <Row>
      <div className="highlight-bar-charts">
    <ResponsiveContainer width="99%" height={400}> 
          <LineChart
            data={data}
            margin={{ top: 30, right: 20, bottom: 10, left: 20 }}
          >
            <CartesianGrid stroke='#f5f5f5'/>
            <XAxis dataKey="name" padding={{left: left, right: right}} tick={true} 
            // domain={['dataMin -5', 'dataMax + 5']}
            />
            {/* <YAxis  label={{ value: 'Index', angle: -90, position: 'insideLeft' }} /> */}
            
            <Tooltip />
            {
                dynamicLegend.map(() => {
                          if(currentVariable == "v")
                            return ([
                            <Line type="monotone" name={<MathJax.Context input='ascii'><MathJax.Node>Q_u</MathJax.Node></MathJax.Context> } dataKey="Q_u" stroke="#f00" />, <YAxis  label={{ value: 'm\u00B3/s', angle: -90, position: 'insideLeft' }} />]);
                          else if (currentVariable == "salt")
                          return ([<Line type="monotone" name={<MathJax.Context input='ascii'><MathJax.Node>L_x</MathJax.Node></MathJax.Context> } dataKey="Lx" stroke="#f00" />, <Line type="monotone" name={<MathJax.Context input='ascii'><MathJax.Node>L_(x_(obs))</MathJax.Node></MathJax.Context>} dataKey="Lx_obs" stroke="#000" fill="#000" />, <YAxis  label={{ value: 'Km', angle: -90, position: 'insideLeft' }} /> ]);
                          else 
                            return ([<Line type="monotone" name={<MathJax.Context input='ascii'><MathJax.Node>S_(u""l)^(ebm)</MathJax.Node></MathJax.Context> } dataKey="S_u" stroke="#f00" />, <Line type="monotone" name={<MathJax.Context input='ascii'><MathJax.Node>S_(obs)</MathJax.Node></MathJax.Context> } dataKey="S_u_obs" stroke="#000" />, <YAxis  label={{ value: 'psu', angle: -90, position: 'insideLeft' }} />]);
                })
  }
            <Legend width={'50%'} wrapperStyle={{ top: '90%', left: 10,right: 10, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '10px' }} />
          </LineChart> 
      </ResponsiveContainer>    
      </div>
      </Row>
      </>
    );
  }
}
export default  StreamingChart;

import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  UncontrolledTooltip,
  Table
} from "reactstrap";

// core components

function Publications() {
  return (
    <>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center title" md="6">
            <h2>Publications</h2>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <div className="text-center">
              {/* <h6 className="title-uppercase">Last update: June, 2020</h6> */}
            </div>
          </Col>

          <Col className="ml-auto mr-auto" md="8">

            <Card
              data-radius="none"
              style={{
                backgroundImage:
                  "url(" +
                  require("assets/img/sections/ebm/publications.jpg") +
                  ")",
              }}
            />
            <p className="image-thumb text-center">
              Last update: April, 2021
                    </p>
            <div className="article-content">
              <h4>
                <strong>Peer-reviewed papers</strong>
                
              </h4>
              <p>
                <ul>
                  <li>
                  •	Verri, G., Pinardi, N., Bryan, F., Tseng, Y. H., Coppini, G., &amp; Clementi, E. (2020). A box model to represent estuarine dynamics in mesoscale resolution ocean models. Ocean Modelling, 148, 101587.
                  doi: <a href="https://doi.org/10.1029/2020MS002276" target="_blank">https://doi.org/10.1029/2020MS002276</a>
                  </li>
                  <li>
                  •	Verri, G., Mahmoudi Kurdistani, S., Coppini, G., & Valentini, A. (2021). Recent advances of a box model to represent the estuarine dynamics: time‐variable estuary length and eddy diffusivity. Journal of Advances in Modeling Earth Systems, e2020MS002276.
                   doi: <a href="https://doi.org/10.1016/j.ocemod.2020.101587" target="_blank">https://doi.org/10.1016/j.ocemod.2020.101587</a>
                </li>
                </ul>
              </p>
              <h4>
                <strong>Conference papers</strong>
              </h4>
              <p>
              <ul>
                <li>
                •	Verri, G., Mahmoudi Kurdistani, S., Pinardi N., Coppini, G., Valentini, A., Conte D. <a href="https://meetingorganizer.copernicus.org/EGU21/EGU21-14887.html" target="_blank">Future salt intrusion climate scenarios: the case of the Po river</a> EGU General Assembly  2021
                </li>
                  <li>
                  G. Verri, N. Pinardi, F. Bryan et al., <a href="https://meetingorganizer.copernicus.org/EGU2018/EGU2018-15196.pdf" target="_blank">A box model to represent estuarine dynamics in mesoscale resolution ocean models.</a> EGU General Assembly 2018
                </li>
                </ul>
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Publications;

import React, { Component } from 'react';
import Select from "react-select";
import StreamingChart from '../../assets/canvasjs/chart.react';

import {
  Row,
  Col,
  Container,
  Navbar
} from "reactstrap";

import {
  ResponsiveContainer
} from 'recharts';

const selectDateOptions = [
  { value: "18", label: "2018" },
  { value: "17", label: "2017" },
  { value: "16", label: "2016" },
  { value: "15", label: "2015" },
  { value: "14", label: "2014" },
  { value: "13", label: "2013" },
  { value: "12", label: "2012" },
  { value: "11", label: "2011" },
  { value: "10", label: "2010" },
  { value: "09", label: "2009" },
  { value: "08", label: "2008" },
  { value: "07", label: "2007" },
  { value: "06", label: "2006" },
  { value: "05", label: "2005" },
  { value: "04", label: "2004" },
  { value: "03", label: "2003" }

];

const selectVariableOptions = [
  { value: "s", label: "Outflowing salinity" },
  { value: "v", label: "Outflowing volume flux" },
  { value: "salt", label: "Length of salt wedge intrusion" }
];


const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "9999"
  })
};

function changingDateSelection(selectedValue) {
  //AreaActions.setArea(value);
  //sDate = selectedValue.value;
 
  
  //console.log("va ", selectedValue.value);
  new StreamingChart().handleChangeData(selectedValue.value);
  
}


function Chart() {

  const [selectedDate, setMapDate] = React.useState();
  const [selectedVariable, setVariable] = React.useState();

  return (
    <>
      <div>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center title" md="6">
              <h2>EBM outcomes</h2>
            </Col>
          </Row>
          <Row>
            <ResponsiveContainer width='100%' aspect={3.0 / 1.5} className="customChart">
              <StreamingChart/>
            </ResponsiveContainer>
          </Row>
        </Container>
      </div>
    </>
  );
}


export default Chart;

import React from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";

// reactstrap components
import { 
    Button,
    Collapse,
    Container,
    DropdownToggle,
  DropdownMenu,
  DropdownItem,
    Nav,
    NavLink,
    NavItem,
    UncontrolledDropdown
 } from "reactstrap";

 import Scroll from 'react-scroll';
const ScrollLink = Scroll.ScrollLink

// core components

function EBMNavbar() {
  return (
    <>
      <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Model description
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/the-estuary-box-geometry-and-assumption" tag={Link}>
                  The Estuary Box: geometry and assumptions
                  </DropdownItem>
                  <DropdownItem to="/the-physics-core" tag={Link}>
                  The physics core
                  </DropdownItem>
                  <DropdownItem to="/the-model-findings" tag={Link}>
                  The model findings
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                Handle the CMCC EBM  
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/ebm-outcomes" tag={Link}>
                  EBM outcomes
                  </DropdownItem>
                  <DropdownItem to="/coupled-ocean-model-outcomes" tag={Link}>
                  Coupled ocean model outcomes
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="active">
                <NavLink href="/#team">
                  System Team <span className="sr-only">(current)</span>
                </NavLink>
              </NavItem>
              <NavItem className="active">
                <NavLink href="/publications">
                  Publications <span className="sr-only">(current)</span>
                </NavLink>
              </NavItem>
              {/* <NavItem className="active">
                <NavLink href="#" >
              <LinkScroll
                activeClass="active"
                to="supporting-projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Section 2
              </LinkScroll>
              </NavLink>
              </NavItem> */}
              <NavItem className="active">
                 <NavLink href="/#supporting-projects">
                  Supporting projects <span className="sr-only">(current)</span>
                </NavLink> 
              </NavItem>
              <NavItem className="active">
                <NavLink href="/news" >
                  News <span className="sr-only">(current)</span>
                </NavLink>
              </NavItem>
            </Nav>
    </>
  );
}

export default EBMNavbar;

import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  UncontrolledTooltip,
  Table
} from "reactstrap";

import MathJax from "react-mathjax2";

const first = '"(1) "Q_(u""l)^(ebm) = Q_(river) + Q_(ll)^(ocean) + HL_yu_(tide_f)'
const second = '"(2) "S_(u""l)^(ebm) Q_(u""l)^(ebm)= S_(ll)^(ocean) Q_(ll)^(ocean) + barS_(ocean)HL_yu_(tide_f) + K_(S_H)HL_y barS_(ocean)/L_x'

// core components

function ModelFindings() {
  return (
    <>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center title" md="6">
            <h2>Model description</h2>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <div className="text-center">
              <h3 className="title">
                3 - The model findings
                      </h3>
              <h6 className="title-uppercase">Last update: April, 2021</h6>
            </div>
          </Col>

          <Col className="ml-auto mr-auto" md="8">
            <div className="article-content">

              <p>
              The CMCC EBM is found to provide a reliable representation of the net release at river mouths, in terms of volume flux &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>Q_(u""l)^(ebm)</MathJax.Node></MathJax.Context> &nbsp;
                      and salinity &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>S_(u""l)^(ebm)</MathJax.Node></MathJax.Context> , &nbsp;
                      as well as the salt-wedge intrusion length &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>L_x</MathJax.Node></MathJax.Context> . &nbsp;
              </p>
              <p>
              Figure 3 shows the Po delta system and focuses on the Po di Goro branch, which is 45km long and conveys around 13% of the Po river runoff. It holds a salt-wedge estuary characterized by a strong inflow of salt sea water and an average length of 15km.
              </p>
              <div className="responsive-cards">
                <Card
                  data-radius="none"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/ebm/po-delta.jpg") +
                      ")",
                  }}
                />
              </div>
              <blockquote className="blockquote">
                <p>
                  Figure 3. The map of the Po delta system(Google Earth image). The red path highlights the Po di Goro branch. The markers identify the Po di Goro mouth and the two buoys located few km offshore the Goro mouth
                      </p>
              </blockquote>

              <p>
              Figure 4 shows the time series of modeled Lx during 2003-2017 and provides the  comparison with the available measures of the salt wedge intrusion length based on the samplings carried out by the Agency for Prevention, Environment and Energy of Emilia-Romagna (Arpae) during medium tidal level, thus avoiding ebb or flood tide peak events
             <div className="responsive-cards">
                  <Card
                    data-radius="none"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/sections/ebm/validationCMCCEBM_3.jpg") +
                        ")",
                    }}
                  />
                </div>
                <blockquote className="blockquote">
                  <p>
                    Figure 4. Timeseries of Lx modeled by CMCC EBM v2 over 2003-2017. The observed values are overlapped
                      </p>
                </blockquote>
              </p>

              <p>
                Figure 5 offers the validation of the CMCC EBM estuarine outflowing salinity over 2018. The black timeseries is the surface salinity observed at the Manufatto gauge which is located close to the Po di Goro mouth. The red timeseries is the one provided by CMCC EBM. The comparison shows a correlation of 85% and a BIAS equal to 0.42 psu.
             <div className="responsive-cards">
                  <Card
                    data-radius="none"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/sections/ebm/validationCMCCEBM.jpg") +
                        ")",
                    }}
                  />
                </div>
                <blockquote className="blockquote">
                  <p>
                    Figure 5. Validation of the CMCC EBM by comparing the modeled and the observed salinity at the Po di Goro mouth over 2018
                      </p>
                </blockquote>
              </p>
              <p>
              The CMCC EBM has been coupled with a mesoscale resolving ocean model based on NEMO code covering the Central Mediterranean Sea (see Verri et al., 2018 for details on the ocean model configuration and numerical set-up). This means that the salinity and volume flux entering the NEMO code at river mouths locations (in terms of surface boundary conditions, see Appendix B of Verri et al 2020 for more details) are the CMCC EBM outcomes.
              </p>
              <p>
              The CMCC EBM+NEMO coupled system has been compared the classical “climatological approach” based on NEMO stand-alone forced at the Po river mouths with climatological discharge and a constant value of salinity, i.e. 17 psu according to an heuristic reasoning. Moreover another coupled system has been considered and tested: it is based on coupling NEMO with another EBM developed by the University of Connecticut (UCONN) and the National Center for Atmospheric Research (NCAR), hereafter named UCONN EBM (Sun et al., 2017).
              </p>
              <p>
              Figure 6 compairs the daily sea surface salinity of the shelf sea adjacent to the Po delta mouths during an upwelling wind regime for the western coast of the Adriatic basin which is expected to enhance the river plume intensity and extension. The experiments performed by coupling the EBMs with the NEMO model represent a well defined river plume (middle and right panels of Fig.5) while the climatological approach shows a much weaker development (left panel of Fig.5)
              </p>
              <p>
              The comparison with the salinity values recorded by two buoys located few km offshore the Po river mouths, i.e. a CNR ISMAR buoy and a ArpaE buoy, is provided in Figures 6 and 7 and confirms the added value of working with the coupled system CMCC EBM+NEMO which is the one showing the best statistics
             <div className="responsive-cards">
                  <Card
                    data-radius="none"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/sections/ebm/dailySSS.jpg") +
                        ")",
                    }}
                  />
                </div>
                <blockquote className="blockquote">
                  <p>
                    Figure 6. Daily SSS (psu) of the Po ROFI when upwelling wind regime, i.e. on May 20th 2009. Left: Exp1, NEMO with climatological river release. Middle: Exp2, coupled UCONN EBM+NEMO. Right: Exp3, coupled CMCC EBM+NEMO. Dots and arrows indicate the locations of Po di Goro mouth and ISMAR and ARPAE buoys
                      </p>
                </blockquote>
              </p>
              <p>
              <div className="responsive-cards">
                  <Card
                    data-radius="none"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/sections/ebm/timeseries-salinity.jpg") +
                        ")",
                    }}
                  />
                </div>
                <blockquote className="blockquote">
                  <p>
                    Figure 7. Time series of observed and modelled salinity (psu) at ISMAR buoy. The gaps in the black line are missing observations 
                      </p>
                </blockquote>
                <div className="responsive-cards">
                  <Card
                    data-radius="none"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/sections/ebm/timeseries-salinity-arpae.jpg.jpg") +
                        ")",
                    }}
                  />
                </div>
                <blockquote className="blockquote">
                  <p>
                    Figure 8. Time series of observed and modelled salinity (psu) at ArpaE buoy. The gaps in the black line are missing observations 
                      </p>
                </blockquote>
              </p>
              <p>
              To further point out the strengths of the CMCC EBM, a completely different case study has been considered, i.e. the highly stratfied estuary of the Ofanto river, which is a semi-perennial river flowing through the Southern Italy and ending into the Southern Adriatic Sea with a low tidal inflow at river mouth. Similarly to the previous case study of the Po di Goro, the CMCC EBM+NEMO coupled system has been tested and compared with the UCONN EBM+NEMO coupled system and with the classical climatological approach, the latter using monthly climatologies for the Ofanto runoff and a constant salinity of 15 psu. Moreover a fourth experiment, referred to as the “explicit estuary”,has been performed with NEMO stand-alone, by modifying the model coastline to include 10 km inlet simulating the Ofanto estuary. This “explicit estuary” experiment is forced at the inlet head with the runoff computed by the hydrological model WRF-Hydro (Veri et al., 2017) and with salinity equal to zero. This experiment is a coarse representation of the Ofanto estuary geometry and it allows for the salt intrusion to occur due to the physics of the mesoscale eddy resolving ocean model. It is indeed a coarsely resolved estuary but it contains 6 vertical levels where the salt intrusion can develop. Thus, it is considered our “benchmark experiment” for the Ofanto estuary, to be used for comparison with the other three experiments because no observational data are available in the coastal area. Figure 8 shows the daily sea surface salinity in the shelf sea off the Ofanto outlet during an upwelling wind regime. The experiments performed by coupling the EBMs with the NEMO model represent a well-defined river plume, are close to the benchmark experiment and outperform the climatological experiment, which is unable to reproduce the plume.
              <div className="responsive-cards">
                  <Card
                    data-radius="none"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/sections/ebm/dailySSS-ofanto.jpg") +
                        ")",
                    }}
                  />
                </div>
                <blockquote className="blockquote">
                  <p>
                    Figure 9. Daily SSS (psu) of the Ofanto ROFI when upwelling wind regime, i.e. on March 3rd 2011. TopLeft: Exp1, NEMO with explicit estuary set-up. TopRight: Exp2, NEMO with climatological river release. BottomLeft: Exp3, coupled UCONN-NCAR EBM+NEMO BottomRight: Exp4, coupled CMCC EBM+NEMO 
                      </p>
                </blockquote>
              </p>
            </div> 

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ModelFindings;

import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  Table
} from "reactstrap";

//import 'patch.js';
import MathJax from "react-mathjax2";

import 'katex/dist/katex.min.css'
import Latex from 'react-latex-next'


const first = '"(1) "Q_(u""l)^(ebm) = Q_(river) + Q_(ll)^(ocean) + HL_yu_(tide_f)'
const second = '"(2) "S_(u""l)^(ebm) Q_(u""l)^(ebm)= S_(ll)^(ocean) Q_(ll)^(ocean) + barS_(ocean)HL_yu_(tide_f) + K_(S_H)HL_y barS_(ocean)/L_x'
const third = '(3) \\quad \\frac{L_x}{H} = 10 F_r^{-0.3} {\\bigg(\\frac{\\rho_{ll}^{ocean}}{\\rho_{river}}\\bigg)}^{40} {\\bigg(\\frac{H}{y}\\bigg)}^{-4.6} {\\bigg(\\frac{Q_{tidef}}{Q_{river}}\\bigg)}^{0.3} {\\bigg(\\frac{Q_{ll}^{ocean}}{Q_{river}}\\bigg)}^{-0.01}'
const fourth = '\\begin{equation} (4) \\quad C_k= \\begin{cases} 200{\\bigg(\\frac{\\rho_{ll}^{ocean}}{\\rho_{river}}\\bigg)}^{20}{\\bigg(\\frac{u_{tide_f}}{u_{river}}\\bigg)}^{0.1}e^{-2000F_t^{2}\\frac{L_y}{H}}\\;\\;\\;\\; \\mbox{   if }Q_{river} \\leq \\overline{Q_{river}} \\\\ \\bigg(\\frac{\\rho_{ll}^{ocean}}{\\rho_{river}}\\bigg)^{20}\\bigg(\\frac{u_{tide_f}}{u_{river}}\\bigg)^{-0.5}e^{-2000F_t^{2}\\frac{L_y}{H}} \\;\\;\\;\\;\\;\\;\\;\\;  \\mbox{    if } Q_{river}>\\overline{Q_{river}}\\end{cases} \\end{equation}'
const fiveth = 'where the river Froude number is $F_r=\\frac{u_{river}}{\\sqrt{\\frac{H}{2}g}}$ and the tidal Froude number is $F_t=\\frac{u_{tide_f}}{\\sqrt{Hg}}$.'
// core components

function PhysicsCore() {
  return (
    <>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center title" md="6">
            <h2>Model description</h2>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <div className="text-center">
              <h3 className="title">
                2 - The physics core
                      </h3>
              <h6 className="title-uppercase">Last update: April, 2021</h6>
            </div>
          </Col>

          <Col className="ml-auto mr-auto" md="8">
            <div className="article-content">
              <p>
                The CMCC EBM physics core consists of a conservation equation for volume flux (eq.(1)) and a conservation equation for the salt flux (eq.(2)) which come out respectively from the volume integral of the continuity equation and the salinity advection-diffusion equation. A comprehensive demonstration of the two equations can be found in the Appendix of Verri et al., 2020.</p><p> Moreover, two non-dimensional equations provide the time-variable estuary length (i.e. the length of the salt wedge intrusion) and the time-variable horizontal eddy diffusivity (Verri et al 2021).</p>
              <p>
                The model sketch is drawn in Figure 2 and the full list of input and output fields is provided in the Table 1.
                    </p>
              <p>
                The model assumptions are (i) no surface layer forcing and (ii) incompressible hypothesis in the continuity equation. Moreover the model equations are averaged over the diurnal tidal cycle which includes both the flood tide and the ebb tide phases.
                    </p>
              <p>
              The tidally averaged conservation equations read as below:
                    </p>
              <div className="noclick">
                <MathJax.Context input='ascii'>
                  <div>
                    <MathJax.Node>{first}</MathJax.Node>
                  </div>
                </MathJax.Context>
              </div>

              <div className="noclick">
                <MathJax.Context input='ascii'>
                  <div>
                    <MathJax.Node>{second}</MathJax.Node>
                  </div>
                </MathJax.Context>
              </div>

              <p>
                The input fields are: the volume flux &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>Q_(ll)^(ocean)</MathJax.Node></MathJax.Context> &nbsp;
                      and salinity &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>S_(ll)^(ocean)</MathJax.Node></MathJax.Context> &nbsp;
                      of the inflowing ocean water through the lowe layer, the depth averaged ocean salinity at the estuary mouth &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>barS_(ocean)</MathJax.Node></MathJax.Context> ,&nbsp;
                      the tidal velocity normal to the estuary mouth &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>u_(tide_f)</MathJax.Node></MathJax.Context> &nbsp;
                      during the flood tide phase and the river volume flux &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>Q_(river)</MathJax.Node></MathJax.Context> &nbsp;
                      at the estuary head. The volume flux &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>Q_(u""l)^(ebm)</MathJax.Node></MathJax.Context> &nbsp;
                      and salinity &nbsp;
                      <MathJax.Context input='ascii'><MathJax.Node>S_(u""l)^(ebm)</MathJax.Node></MathJax.Context> &nbsp;
                      of the outflowing estuarine water through the upper layer are the model unknowns.
                    </p>
              <p>
                With regard to the tidal pumping, we follow the theoretical representation proposed by Simpson et al 1990 with a barotropic tidal inflow during the flood tide and a stratified outflow (through the upper layer) during the ebb tide which is embedded in the unknown estuarine water outflow.
                The CMCC EBM associates the estuarine mixing with the eddy diffusivity and explicitly treats the along-estuary (horizontal) eddy diffusivity as driven by tides. The horizontal eddy diffusivity reads
                &nbsp;
                    <MathJax.Context input='ascii'><MathJax.Node>K_(S_H) = C_K L_y u_(tide_f)</MathJax.Node></MathJax.Context> &nbsp;
                    following MacCready (1999) and Banas (2004) and &nbsp;
                    <MathJax.Context input='ascii'><MathJax.Node>C_K</MathJax.Node></MathJax.Context> &nbsp;
                    is the non-dimensional eddy diffusivity coefficient representative of the competition between riverine and marine waters. Literature review shows a wide range of values for &nbsp;
                    <MathJax.Context input='ascii'><MathJax.Node>C_K</MathJax.Node></MathJax.Context> &nbsp;
                    from (o.o.m 10<sup>-2</sup> to 10<sup>2</sup>) depending on the estuary geometry and the level of stratification (MacCready 2004; MacCready and Geyer 2010).
                    <MathJax.Context input='ascii'><MathJax.Node>C_K</MathJax.Node></MathJax.Context> &nbsp;
              </p>

               <p>     
                    {/* NON PIU */}
                    Recent model advances (Verri et al 2021) include two additional non-dimensional equations to make the estuary length &nbsp;
                    <MathJax.Context input='ascii'><MathJax.Node>L_x</MathJax.Node></MathJax.Context> &nbsp;
                    and the horizontal eddy diffusivity &nbsp;
                    <MathJax.Context input='ascii'><MathJax.Node>C_K</MathJax.Node></MathJax.Context> time-variable instead of considering them as static parameters. &nbsp;
                    The non-dimensional equations include all the “preconditioning factors” of the estuarine overturning circulation and mixing processes i.e. the velocity shear, the horizontal density (i.e. salinity) gradient and the estuary geometry. These advances allow a more realistic representation of the competition between riverine and marine waters and are found to enhance the model capability to reproduce the salinity at the estuary mouth. Moreover the time-variable estuary length represents the length of the salt wedge intrusion and makes the model capable to produce historical recontructions and future scenarios about the salinization of the riverine waters. 
                    The non-dimensional equations are based on the theorem of Buckingham and the Incomplete Self-Similarity (Barenblatt (1987)) and they read as below:
                     <MathJax.Context input='tex'><div><MathJax.Node>{third}</MathJax.Node></div></MathJax.Context> 
                     <Latex>{fourth}</Latex> &nbsp;
                     <div><Latex>{fiveth}</Latex></div> 
                     The only parameters of the CMCC EBM to be provided are the estuary width Ly and the depth H at the river mouth. This minimal calibration and the short CPU time make the CMCC EBM suitable for coupling with mesoscale ocean and hydrology models to produce operational forecasts and climate scenarios”.
              </p>      
                    
              <div className="responsive-cards">
                <Card
                  data-radius="none"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/ebm/sketch.jpg") +
                      ")",
                  }}
                />
              </div>
              <blockquote className="blockquote">
                <p>
                  Figure 2: Sketch of the CMCC EBM. The volume fluxes entering or exiting the box along with their salinities are represented by arrows. Black arrows stand for input values from the ocean and river models, red arrows for the unknowns solved by the EBM. The pairs of blue arrows represent the tidal mixing
                      </p>
                <footer>
                  <br></br>
                      CMCC EBM Input and Output Fields
                      </footer>
              </blockquote>
              <p>
                <Col className="ml-auto mr-auto" md="10">
                  <h4 className="title">
                    <small>INPUT FIELDS</small>
                  </h4>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>Q_(ll)^(ocean) = u_(ll)^(ocean) * L_y * H/2</MathJax.Node></MathJax.Context>
                          </p>
                          </th>
                        <th>
                          <p>
                          volume flux of ocean water inflow through the lower layer (m<sup>3</sup> s<sup>-1</sup>) where &nbsp;
                          <MathJax.Context input='ascii'><MathJax.Node>u_(ll)^(ocean)</MathJax.Node></MathJax.Context>&nbsp;
                          is the velocity of the lower layer ocean water inflow (m s<sup>-1</sup>)
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>Q_(river)</MathJax.Node></MathJax.Context>
                          </p></td>
                        <td>
                          <p>
                            volume flux of river streamflow through the upper layer at the estuary head (m<sup>3</sup> s<sup>-1</sup>)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>S_(ll)^(ocean)</MathJax.Node></MathJax.Context>
                          </p></td>
                        <td>
                          <p>
                          salinity of baroclinic ocean water inflow through the lower layer (psu)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>barS_(ocean)</MathJax.Node></MathJax.Context>
                          </p></td>
                        <td>
                          <p>
                          salinity of barotropic ocean water inflow through the water column H (psu)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>Q_(tide_f)^(ocean) = u_(tide_f) * L_y * H</MathJax.Node></MathJax.Context>
                          </p>
                          </th>
                        <th>
                          <p>
                          volume flux of ocean water inflow due to tides through both layers (m<sup>3</sup> s<sup>-1</sup>) where &nbsp;
                          <MathJax.Context input='ascii'><MathJax.Node>u_(tide_f)</MathJax.Node></MathJax.Context>&nbsp;
                          is the velocity of the ocean water inflow due to tides (m s<sup>-1</sup>)
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </Table>

                  <h4 className="title">
                    <small>OUTPUT FIELDS</small>
                  </h4>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>Q_(u""l)^(ebm) </MathJax.Node></MathJax.Context>
                          </p>
                          </th>
                        <th>
                          <p>
                          volume flux of estuarine water outflow through upper layer (m<sup>3</sup> s<sup>-1</sup>)
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>S_(u""l)^(ebm)</MathJax.Node></MathJax.Context>
                          </p></td>
                        <td>
                          <p>
                          salinity of estuarine water outflow through upper layer (psu)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>L_x</MathJax.Node></MathJax.Context> &nbsp;
                          </p></td>
                        <td>
                          <p>
                          estuary length (m)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><p>
                          <MathJax.Context input='ascii'><MathJax.Node>C_K</MathJax.Node></MathJax.Context> &nbsp;
                          </p></td>
                        <td>
                          <p>
                          on dimensional eddy diffusivity coefficient 
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PhysicsCore;

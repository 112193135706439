import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
// reactstrap components
import { Button, Form, Input, Container, Row, Card, Col } from "reactstrap";

// core components
import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import FooterCadet from "components/Footers/FooterCadet.js";

function EBMContactUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });
  return (
    <>
      <WhiteNavbar />
      <div className="main">
        <div className="section section-gray">
          <Container>
          <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Get in touch with us</h2>
                <p>
                For any questions and curiosity please send an email to giorgia.verri@cmcc.it
                </p>
                </Col>
              </Row>

            <div className="blog-post sidebar-collapse blog-post">
            <div className="responsive-cards">
                <Card
                  data-radius="none"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/ebm/contact-us.jpg") +
                      ")",
                  }}
                />
              </div>
      </div>
     
          </Container>
        </div>
      </div>
      <FooterCadet />
    </>
  );
}

export default EBMContactUs;

import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function EBMLatestNews() {
  return (
    <>
      <div className="section secion-blog cd-section" id="blogs">
        <div className="blog-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Latest News</h2>
                <br />
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="4">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/sections/ebm/news/ebm-news-1.jpg")}
                        />
                      </div>
                    </Col>
                    <Col md="8">
                      <CardBody>
                        <h6 className="card-category text-info">OPERANDUM</h6>
                        <CardTitle tag="h3">
                        OPERANDUM
                        </CardTitle>
                        <p className="card-description">
                          <a href="https://storymaps.arcgis.com/stories/30e3c3143c42408bb081a252bfd9cb1f" target="_blank" rel="noopener noreferrer">
                          The OPERANDUM interactive page with elaborated details and solutions for the Italian Open Air Laboratory
                         </a>
                        </p>
                        <p className="author">
                        <i className="nc-icon nc-calendar-60" />
                          Posted on 04/27/2020
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="8">
                    <CardBody>
                        <h6 className="card-category text-info">EurekAlert</h6>
                        <CardTitle tag="h3">
                          EurekAlert News release
                          </CardTitle>
                        <p className="card-description">
                          <a href="https://www.eurekalert.org/pub_releases/2020-03/cf-e-rht030520.php" target="_blank" rel="noopener noreferrer">
                          EurekAlert News release: “Rivers: how they contribute to better understand the Mediterranean Sea dynamics”
                         </a>
                        </p>
                        <p className="author">
                        <i className="nc-icon nc-calendar-60" />
                          Posted on 03/05/2020
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="4">
                      <div className="card-image">
                       <img
                            alt="..."
                            className="img"
                            src={require("assets/img/sections/ebm/news/ebm-news-2.jpg")}
                          />
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="4">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/sections/ebm/news/ebm-news-3.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col md="8">
                    <CardBody>
                        <h6 className="card-category text-info">CMCC</h6>
                        <CardTitle tag="h3">
                          CMCC News release
                          </CardTitle>
                        <p className="card-description">
                          <a href="https://www.cmcc.it/article/rivers-how-they-contribute-to-better-understand-the-mediterranean-sea-dynamics" target="_blank" rel="noopener noreferrer">
                         “Rivers: how they contribute to better understand the Mediterranean Sea dynamics”
                         </a>
                        </p>
                        <p className="author">
                        <i className="nc-icon nc-calendar-60" />
                          Posted on 03/04/2020
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default EBMLatestNews;

import React, { Component } from 'react';
import Select from "react-select";

// core components
import 'leaflet/dist/leaflet-src'
import '../../assets/css/leaflet.css'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet.wms/lib/leaflet';
import 'leaflet.wms/dist/leaflet.wms';
import 'leaflet.wms/lib/require';
import 'leaflet.sync/L.Map.Sync'

import {
  Row,
  Col,
  Container,
  Navbar,
  FormGroup
} from "reactstrap";

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "9999"
  })
};

const selectDateOptions = [
  { value: "15", label: "20090515 " },
  { value: "16", label: "20090516" },
  { value: "17", label: "20090517" },
  { value: "18", label: "20090518" },
  { value: "19", label: "20090519" },
  { value: "20", label: "20090520" },
  { value: "21", label: "20090521" }

];

var map, map2;
const selectVariableOptions = [
  { value: "s", label: "Salinity " }
 
 ];

var sDate = 15, defaultLayer="EBM_20090521_200905"+sDate+"_0000_0_Salinity", defaultMapfile="EBM_Salinity_200905"+sDate;

function changingDateSelection(selectedValue) {
  //AreaActions.setArea(value);
  sDate = selectedValue.value;
  //document.getElementById("ebmMapId").removeAttribute("");
  console.log("va ", selectedValue.value);
  console.log("sDate ", sDate);
  console.log("defaultLayer ", defaultLayer);
  addingLayer(selectedValue.value);
  

  ////var wmsLayer = L.tileLayer.wms('http://ows.mundialis.de/services/service?', wmsOptions).addTo(map);
  
}
var salinityCLIM, salinityEBM;

function addingLayer(day){
  
  var base_url = 'https://medfs.cmcc.it/cgi-bin/mapserv?';
  if (salinityCLIM != null) map.removeLayer(salinityCLIM);
  if (salinityEBM != null) map2.removeLayer(salinityEBM);
  
  var mapfileCLIM = "/home/www/ebm/mapserver/mapfiles/CLIM_Salinity_200905"+day+".map";

     salinityCLIM = L.tileLayer.wms(base_url, {
        version: '1.3.0',
        map: mapfileCLIM,
        layers: 'CLIM_20090521_200905'+day+'_0000_0_Salinity',
        transparent: true,
        format: 'image/png',
        crs: L.CRS.EPSG3857,
        srs: L.CRS.EPSG3857
      });
  
      salinityCLIM.addTo(map);

      var mapfileEBM = "/home/www/ebm/mapserver/mapfiles/EBM_Salinity_200905"+day+".map";

     salinityEBM = L.tileLayer.wms(base_url, {
      version: '1.3.0',
      map: mapfileEBM,
      layers: 'EBM_20090521_200905'+day+'_0000_0_Salinity',
      transparent: true,
      format: 'image/png',
      crs: L.CRS.EPSG3857,
      srs: L.CRS.EPSG3857
    });

    salinityEBM.addTo(map2);
}

var selectedDate = "", selectedVariable = "";
//  const [selectedDate, setMapDate] = React.useState();
//  const [selectedVariable, setVariable] = React.useState({ value: "s", label: "Salinity " });

class MapServerEBM extends Component {

  componentDidMount() {   
   
    map = L.map('map').setView([45.0446688, 12.963513], 8);
    map2 = L.map('map2').setView([45.0446688, 12.963513], 8);
    var wmsLayer = L.tileLayer.wms('https://ows.terrestris.de/osm/service?', {
    // layers: 'SRTM30-Colored-Hillshade'
    layers: 'OSM-WMS'
    }).addTo(map);  

    var wmsLayer = L.tileLayer.wms('https://ows.terrestris.de/osm/service?', {
      // layers: 'SRTM30-Colored-Hillshade'
      layers: 'OSM-WMS'
      }).addTo(map2);  

    addingLayer(15);

    map.sync(map2);
    map2.sync(map);

  }

  render() {
    return (
      <>
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                <h2>Coupled ocean model outcomes</h2>
                </Col>
              </Row>
              <div className="section section-navigation section-navigation-custom">
        <div id="navbar-full">
          <div className="navigation-example">
            <Navbar className="bg-info" expand="lg">
            <Container>
              <Col md="4">
              <p className="navbar-brand">Select year and variable</p>
              </Col>
              <Col md="4">
              
              <Select 
                    styles={selectStyles}
                    className="react-select react-select-default"
                    classNamePrefix="react-select"
                    name="dateSelect"
                    //value={{ value: "15", label: "20090515" }}
                    defaultValue={{ value: "15", label: "20090515" }}
                    onChange={ (value) => changingDateSelection(value) }
                    //onChange={(value) => setMapDate(value)}
                    //onChange={handleDateChange()}
                    options={selectDateOptions}
                    placeholder="CHOOSE DATE"
                  />
              </Col>
              <Col md="4">
              <Select
                    styles={selectStyles}
                    className="react-select react-select-default"
                    classNamePrefix="react-select"
                    name="defaultSelect"
                    value={{ value: "s", label: "Salinity" }}
                    //onChange={(value) => setVariable(value)}
                    options={selectVariableOptions}
                    placeholder="CHOOSE VARIABLE"
                  />
              </Col>
                 </Container>
                  </Navbar>
                  </div>
                    </div>
                    </div>
                <Row>
                  <Col className="ml-auto mr-auto" md="6">
                    <h5 class="title text-center">NEMO with climatological runoff</h5>
                    <div id="map" ></div>
                  </Col>
                <Col className="ml-auto mr-auto" md="6">
                <h5 class="title text-center">Coupled CMCC EMB+NEMO</h5>
                <div id="map2"></div>
                </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="6">
                  <div id="colorbarH"></div>
                  </Col>
                </Row>
                </Container> 
  </>
      
    );
  }
}
export default MapServerEBM; 

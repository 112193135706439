import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import FooterCadet from "components/Footers/FooterCadet.js";
import EBMLatestNews from "./EBMLatestNews";

function BlogPosts() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <WhiteNavbar />
      <div className="section-space" />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            
          <EBMLatestNews />
            
          </div>
        </div>
      </div>
      <FooterCadet />

    </>
  );
}

export default BlogPosts;

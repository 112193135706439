import React from "react";

// reactstrap components
import { 
    Container,
    Row,
    Col,
    Card
 } from "reactstrap";

// core components

function EstuaryBox() {
  return (
    <>
      <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                <h2>Model description</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <div className="text-center">
                      <h3 className="title">
                      1 - The Estuary Box: geometry and assumption
                      </h3>
                    <h6 className="title-uppercase">Last update: April, 2021</h6>
                  </div>
                </Col>
                
                <Col className="ml-auto mr-auto" md="8">
                <div className="article-content">
                    <p>
                    The CMCC EBM assumes that the estuary region is a two-layer rectangular box with constant width Ly and depth H, and with time-variable length Lx, shown in Figure 1. The estuary head is considered as the last section along the river network moving in the downstream direction where the salinity is still equal to zero and the estuary length, Lx is the time variable distance between the estuary head and the mouth representing the length of the salt wedge intrusion. The Ly, is the estuary width and H is the estuary depth at river mouth which is assumed to be twice the depth at the estuary head.The only open sections are: (i) the cross section at the estuary mouth where the ocean and tidal forcings are provided and (ii) the cross section at the estuary head where the river forcing is provided. The EBM physics is laterally and along channel averaged, steady-state and tidally averaged over the daily tidal cycle, i.e. the lunar day, including both the flood tide and  the ebb tide phases. The heat flux, precipitation and wind stress are not considered. Furthermore, the temperature effects are neglected because the estuary dynamics is, to the first order, dominated by salinity exchanges. 
                    </p>
                    </div>

                    <Card
                      data-radius="none"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/sections/ebm/cube-w.jpg") +
                          ")",
                      }}
                    />
                    <blockquote className="blockquote">
                      <p>
                      Figure 1: Schematic representation of the estuary box: 2-layer rectangular box with L<sub>y</sub>, length L<sub>x</sub>, and depth H. Shaded cross sections are the open boundaries. The mouth of the estuary box is defined at the origin of the x-axis with its positive toward the ocean. The vertical z-axis is defined positive upward with the origin at the bottom of the estuary mouth 
                      </p>
                     
                    </blockquote>
                    
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default EstuaryBox;

import React from "react";

// reactstrap components
import { Badge, Button, Card, Media, Container, Row, Col } from "reactstrap";

// core components

import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import FooterCadet from "components/Footers/FooterCadet.js";

import EstuaryBox from "views/pages/EstuaryBox";
import PhysicsCore from "./pages/PhysicsCore";
import Publications from "./pages/Publications";
import ModelFindings from "./pages/ModelFindings";
import MapServer from "./pages/MapServerEBM";
import EBMLatestNews from "views/presentation-sections/EBMLatestNews";
import Chart from "./pages/Chart";

function BlogPost(props) {
  //document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-post");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  });
  return (
    <>
       <WhiteNavbar />
      <div className="section-space" />
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            
            {props.pagetitle == 'estuary-box'? <EstuaryBox/>: console.log() } 
            {props.pagetitle == 'pyshics-core'? <PhysicsCore/>: console.log() } 
            {props.pagetitle == 'model-findings'? <ModelFindings/>: console.log() } 
            {props.pagetitle == 'publications'? <Publications/>: console.log() } 
            {props.pagetitle == 'mapserver'? <MapServer/>: console.log() } 
            {props.pagetitle == 'chart'? <Chart/>: console.log() } 
            {props.pagetitle == 'news'? <EBMLatestNews/>: console.log() } 
            
          </div>
        </div>
      </div>
      <FooterCadet />
    </>
  );
}

export default BlogPost;

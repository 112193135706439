import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components

function EBMSupportingProjects() {
  return (
    <>
     <div className="section section-gray" id="supporting-projects">
        <Container>
          <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Supporting Projects</h2>
              </Col>
            </Row>
          </Container>
          <Container>
        <Row>
        <Col md="3" sm="6">
            </Col>
          <Col md="3" sm="6">
              <div className="card-big-shadow">
                <Card
                  className="card-just-text"
                  data-color="blue"
                  data-radius="none"
                >
                   <a href="https://marine.copernicus.eu" target="_blank">
                  <CardBody>
                   
                    <CardTitle tag="h4">
                    EU CMEMS
                    </CardTitle>
                    <p className="card-description">
                    CMCC EBM development was initially supported by the EU Copernicus Marine Environment Monitoring Service projects of the Mediterrenean Sea Monitoring and Forecasting (n.74-CMEMS-MFC-MED-N). 
                    </p>
                  </CardBody>
                  </a>
                </Card>
                {/* end card */}
              </div>
            </Col>
            <Col md="3" sm="6">
              <div className="card-big-shadow">
                <Card
                  className="card-just-text"
                  data-color="orange"
                  data-radius="none"
                >
                    <a href="https://www.operandum-project.eu" target="_blank">
                  <CardBody>
                    
                    <CardTitle tag="h4">
                    OPERANDUM
                    </CardTitle>
                    <p className="card-description">
                    The ongoing upgrades are under the umbrella of the H2020 Operandum, a project aiming at reducing hydro-meteorological risks in Europe through the design and development of innovative Nature Based Solutions
                    </p>
                  </CardBody>
                  </a>
                </Card>
                {/* end card */}
              </div>
            </Col>
            <Col md="3" sm="6">
            </Col>
          </Row>
         </Container>
      </div>
    </>
  );
}

export default EBMSupportingProjects;
